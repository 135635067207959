$(document).ready(function () {


  /*consent*/
  function consent(){
      if(UC_UI.getServicesBaseInfo().find(data => (data.categorySlug === "marketing")).consent.status){
          return 1;
      }else{
          return 0;
      }
  }


  /* Special Banner in Home Popular Articles */

  $("#specialBanner").mouseover(function () {
    $("#customImageText").css("display", "block");
  });
  $("#specialBanner").mouseout(function () {
    $("#customImageText").css("display", "none");
  });

  $('#bannerLink').click(function (event) { trackOutboundLink(event, 'https://players.brightcove.net/4309680634001/default_default/index.html?videoId=6085272476001'); });
  /*
  $(document).on("mouseover", '#specialBanner', function () {
    document.getElementById("customImageText").style.display = "block";
  });*/

  refreshColumns('.newest-articles', '.articleContent');

  $('.banner.slider_start').each(function () {
    checkSliderAd($(this));
  });
  $('.banner.slider_event_french').each(function () {
    checkSliderAd($(this));
  });
  $('.banner.slider_fachthemen').each(function () {
    checkSliderAd($(this));
  });

  refreshAds();

  var testArticle = $(('.article-475276') || ('.article-477059') );
  if (testArticle.length > 0) {
    
    // Alle <p>-Tags auf der Seite auswählen
    const paragraphs = document.querySelectorAll(".csArticle-page_wrapper p");
    
    paragraphs.forEach(paragraph => {
        // Ein neues <img>-Element erstellen
        const img = document.createElement("img");
        img.src = "https://universimed.spsts.de/__image/a/478550/alias/xl/v/1/c/4/ar/flexible/fn/TESTIMAGE81%20Kopie.jpg"; // Platzhalterbild mit gewünschter Größe
        img.alt = "Ein Bild hinter dem Paragraphen";
        img.style.display = "block"; // Sicherstellen, dass es unter dem Text erscheint
        img.style.margin = "10px auto"; // Abstand zum Text hinzufügen und zentrieren
        img.style.width = "300px";
        img.style.height = "250px";
        
        // Das Bild direkt nach dem <p>-Tag einfügen
        paragraph.after(img);
        });
    
  }

  var topicArticle = $('.topic-event-article.csArticle-page_main');


  var gifBannerMobile = $('.banner.event_halfpage_response.gifBanner');
  if (gifBannerMobile.length > 0 && topicArticle.length > 0 && $(topicArticle).data('topic-article') == '2179929') {
    $(gifBannerMobile).css('display', 'inline-block');
  }


  var gifBanner = $('.banner.event_halfpage.gifBanner');
  if (gifBanner.length > 0 && topicArticle.length > 0 && $(topicArticle).data('topic-article') == '2179929') {
    $(gifBanner).show();
    var banner = gifBanner;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw > 1024 && vw <= 1860) {
      var container = $('.unniversimed-container .container .article-content-main');
      var currentSize = parseInt(container.width());
      var marginLeft = parseInt(container.css('marginLeft'));
      var marginRight = parseInt(container.css('marginRight'));
      var total = parseInt(currentSize + marginLeft + marginRight);
      var limit = 680;

      if (vw - total < limit) {
        var defaultTransform = 300;
        var needed = parseInt(vw - currentSize - marginLeft);
        if (needed > 400) {
          needed -= 300;
        }
        var moveContainer = parseInt(defaultTransform - (needed / 2));
        var used = parseInt(marginLeft + (needed / 2) + moveContainer);
        var extra = 0;
        var moveBanner = 15;

        if (vw < 1280) {
          extra = 40;
          moveBanner = 140;
        }

        container.width(currentSize - (used / 3) - extra);
        container.css({ marginLeft: marginLeft - (used / 3) });

        var translateBanner = 0;
        var bannerContainer = banner.find('.bannerContainer');
        bannerContainer.css({ 'transform': 'translateX(' + translateBanner + 'px)' });
        container.css({ 'padding-left': '90px' });
        if (vw >= 1450) {
          bannerContainer.css({ 'margin-left': '-30px' });
        }
      }
    }
  }

  loadAllBanners();

});

function loadAllBanners() {
  var isPromotionArticle = $('.csArticle-page_main.promotion').length > 0;
  $('div.bannerContainer[needLoad]').each(function () {

    $(this).removeAttr('needLoad');
    // var srcbanner = $(this).attr('src');
    // srcbanner = srcbanner+docCheck();
    // console.log("srcbanner: "+srcbanner);
    var iframe = getIframe($(this).attr('src'));
    // var iframe = getIframe('https://universimed.spsts.de/__image/a/2218790/alias/xxl/v/1/c/6/ar/4-3/fn/Teaserbild_iStock-876977734.jpg');

    // var f = document.createElement('iframe');
    // f.src = 'https://universimed.spsts.de/__image/a/2218790/alias/xxl/v/1/c/6/ar/4-3/fn/Teaserbild_iStock-876977734.jpg';
    // f.width = 1000;
    // f.height = 500;
    // $('body').append(f);

    // $(iframe).on("load readystatechange", function(){
    //   alert('loaded');
    // });


    iframe.appendTo($(this));

    $(iframe).load(function () {
      // var adCheckLimit = $(options).data('debug')?-1:0
      var adCheckLimit = 0;
      var banner = $(this).parents('.banner');

      if (($(iframe).contents().text().indexOf('window.asm_start_player') > -1) || ($(iframe).contents().text().indexOf('window.asm_obaid') >= adCheckLimit && !isPromotionArticle)) {

        relocateHalfPageBanner(banner);

        if (banner.parent().data('carousel') != null && banner.parent().data('carousel') == "slider-article-slide") {
          var carousel = banner.parent().data('carousel');
          var index = banner.parent().data('index');

          if (!banner.parent().hasClass('carousel-item')) {
            banner.parent().addClass('carousel-item');
          }

          if (index != null && carousel != null) {
            $('#' + carousel + ' ol li[data-index=' + index + ']').show();
          }
        }

        /*if (banner.hasClass('event_media_rectangle_1') || banner.hasClass('event_media_rectangle_2') || banner.hasClass('event_media_rectangle_3') || banner.hasClass('event_media_rectangle_4') || banner.hasClass('event_media_rectangle_5') || banner.hasClass('event_media_rectangle_6')){
        banner.parent().show();
        var row = banner.closest('.row');
          if (row != null){
             let element = row.find('.article[data-article-index=2]');
             if (element != null){
               element.hide();
             }
         }
        }

         if (banner.hasClass('event_superbanner_4') || banner.hasClass('event_superbanner_5')){
           banner.parent().show();
         }*/

        if (banner.hasClass('artikel_video_pop_up')) {
          checkOverlay(iframe);
        }


        $(iframe).contents().find('head').append(getCssLink());
        banner.show();

        if (banner.hasClass('fachthemen_halfpage')) {
          var offsetTop = $('.theme_list .article.articleContent').offset().top;
          banner.offset({ top: offsetTop });
        }

        if (banner.hasClass('fachthemen_halfpage_fki')) {
          var half = $('.banner.fachthemen_halfpage.long');
          var offsetTop = $('.theme_list .article.articleContent').offset().top;

          var halfPage = $('.fachthemen_halfpage .bannerContainer');
          if (halfPage.length > 0) {
            // console.log(halfPage.offset());
            // console.log(halfPage.position());
            // banner.css({ left: halfPage.position().left })
          }

          if (half.length > 0) offsetTop += 605;

          banner.offset({ top: offsetTop });
        }

        if (banner.hasClass('nejm_media_rectangle_1') || banner.hasClass('nejm_media_rectangle_2')) {
          banner.fadeTo(0, 0);
          setTimeout(function () {
            var offsetTop = $('.nejm_list article').offset().top;
            banner.offset({ top: offsetTop });
            banner.fadeTo(0, 1);
          }, 1000);

        }

      } else {
        if (banner.parent().data('carousel') != null && banner.parent().data('carousel') == "slider-article-slide") {
          var carousel = banner.parent().data('carousel');
          var index = banner.parent().data('index');


          if (index != null && carousel != null) {
            $('#' + carousel + ' ol li[data-index=' + index + ']').remove();
          }
        }
        banner.remove();
      }
    });
  });

}

function checkOverlay(iframe) {
  setTimeout(function () {
    if ($(iframe).contents().find('.asmdivclose').length > 0) {
      checkOverlay(iframe);
    } else {
      $('.artikel_video_pop_up').css('pointer-events', 'none');
    }
  }, 50);
}

function relocateHalfPageBanner(banner) {
  if (banner.hasClass('halfPage') || banner.hasClass('fachthemen_halfpage') || banner.hasClass('artikel_halfpage') || banner.hasClass('event_halfpage') || banner.hasClass('fachthemen_halfpage_fki')
    || banner.hasClass('halfPage_response') || banner.hasClass('fachthemen_halfpage_response') || banner.hasClass('artikel_halfpage_response') || banner.hasClass('event_halfpage_response')) {
    var iframe = $(banner).find('iframe');
    var player = $(iframe).contents().find('[id^=oba-player-divx]');
    if (player.length > 0 && $(player).attr('style').indexOf('300px') > -1) {
      banner.addClass('long');
    }

    if (banner.hasClass('fachthemen_halfpage_fki')) {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      var container = $('.unniversimed-container .container .article-content-main .themen_content');
      var currentSize = parseInt(container.width());
      var marginLeft = parseInt(container.css('marginLeft'));
      var marginRight = parseInt(container.css('marginRight'));
      var total = parseInt(currentSize + marginLeft + marginRight);

      var limit = 100;
      // if (vw - total < limit) {
      if (vw > 1024 && vw <= 1860) {
        if (vw >= 1450) {
          var bannerContainer = banner.find('.bannerContainer');
          bannerContainer.css({ 'margin-left': '-30px' });
        }

        var move = -250;
        if (vw > 1250) {
          move += 10;
        }
        banner.css({ 'transform': 'translateX(' + move + 'px)' });
      }
    }

    if (banner.hasClass('halfPage') || banner.hasClass('event_halfpage') || banner.hasClass('fachthemen_halfpage')) {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if (vw > 1024 && vw <= 1860) {
        var container = $('.unniversimed-container .container .article-content-main');
        if (banner.hasClass('halfPage')) {
          container = $('.unniversimed-container .container .newest-articles');
        }
        else if (banner.hasClass('fachthemen_halfpage')) {
          container = $('.unniversimed-container .container .article-content-main .themen_content');
        }

        var currentSize = parseInt(container.width());
        var marginLeft = parseInt(container.css('marginLeft'));
        var marginRight = parseInt(container.css('marginRight'));
        var total = parseInt(currentSize + marginLeft + marginRight);

        var limit = 680;
        if (banner.hasClass('halfPage')) {
          limit = 400;
        }

        if (vw - total < limit) {
          var defaultTransform = 200;
          if (banner.hasClass('long')) {
            defaultTransform = 300;
          }

          if (banner.hasClass('halfPage')) {
            defaultTransform = 40;
            if (banner.hasClass('long')) {
              defaultTransform = 140;
            }
          }

          var needed = parseInt(vw - currentSize - marginLeft);
          if (needed > 400) {
            needed -= 300;
          }
          var moveContainer = parseInt(defaultTransform - (needed / 2));
          var used = parseInt(marginLeft + (needed / 2) + moveContainer);
          var extra = 0;
          var moveBanner = 15;

          if (vw < 1280) {
            extra = 40;
            moveBanner = 35;
            if (banner.hasClass('event_halfpage') || banner.hasClass('fachthemen_halfpage')) {
              moveBanner = 80;
              if (banner.hasClass('long')) {
                moveBanner = 140;
              }
            }

            if (vw > 1220) {
              moveBanner -= 20;
              if (banner.hasClass('halfPage')) {
                moveBanner += 5;
              }
            }

          }

          container.width(currentSize - (used / 3) - extra);
          container.css({ marginLeft: marginLeft - (used / 3) });

          var translateBanner = (defaultTransform - moveBanner - (used / 2));
          if ((banner.hasClass('event_halfpage') || banner.hasClass('fachthemen_halfpage')) && vw > 1280) {
            translateBanner = 0;
          }

          var bannerContainer = banner.find('.bannerContainer');
          bannerContainer.css({ 'transform': 'translateX(' + translateBanner + 'px)' });

          if (banner.hasClass('event_halfpage') || banner.hasClass('fachthemen_halfpage')) {
            container.css({ 'padding-left': '90px' });
            if (vw >= 1450) {
              if (banner.hasClass('long')) {
                bannerContainer.css({ 'margin-left': '-30px' });
              } else {
                bannerContainer.css({ 'margin-left': '-10px' });
              }
            }
          }

          if (banner.hasClass('fachthemen_halfpage')) {
            var move = -250;
            if (vw > 1250) {
              move += 10;
            }
            if (vw >= 1350 && !banner.hasClass('long')) {
              move += 60;
            }

            banner.css({ 'transform': 'translateX(' + move + 'px)' });
          }
        }
      }
    }

  }
}

function getIframe(src) {
  var srcinf = src;
  //console.log(docCheck());
  
  console.log("srcinf: "+srcinf);
  return $('<iframe />', {
    name: 'adIframe',
    srcdoc: '<script src="' + srcinf + '"></script>',
    class: "iframeAds"
  });
}

function refreshAds() {

  var iframesAll = $('div.adContainer');
  var iframes = $('div.adContainer[needLoad]');

  if ($('.ads_limit').length > 0) {
    $(iframesAll[0]).parents('.ads_options').data('limit', $('.ads_limit').data('limit'));
  }


  if (iframesCache.length > 0 && iframesAll.length !== iframes.length) {
    var options = $(iframesAll[0]).parents('.ads_options');
    reloadIFrame(iframesAll, 0, options);
  }

  if (iframes.length > 0) {
    var options = $(iframes[0]).parents('.ads_options');
    loadIFrame(iframes, 0, options);
  }


}

var iframesCache = [];
function reloadIFrame(iframes, index, options) {

  var position = $(iframes[index]).data('position');
  var containerKey = $(iframes[index]).data('container');
  var articlesKey = $(iframes[index]).data('container-articles');
  var bannerClass = $(iframes[index]).data('banner-class');
  var containerClass = $(iframes[index]).data('container-class');
  var articles = $(articlesKey).not('.ad_hidden');



  var html = iframesCache[index]; //getIframe($(iframes[index]).attr('src'));
  var iframe = $('<iframe />', {
    name: 'adIframe',
    srcdoc: $(html).html(),
    class: "iframeAds"
  });


  if (html) {
    // var adCheckLimit = $(options).data('debug')?-1:0
    var adCheckLimit = 0;

    if (articles.length > position) {

      var article = articles[position];

      var adContainer = createAdContainer(article, bannerClass, containerClass, iframe);


      $(iframe).load(function () {

        if ($(iframe).contents().text().indexOf('window.asm_obaid') >= adCheckLimit) {

          $(iframe).contents().find('head').append(getCssLink());

          adaptMainContainer(article);
          $(adContainer).removeClass('ad_hidden');
          if ($(adContainer).hasClass('start_media_rectangle_2')) {
            $('.unniversimed-container .newest-articles .articleContent[data-article-index=5]').hide();
          }

          if (index < iframes.length - 1) {
            reloadIFrame(iframes, index + 1, options);
          } else refreshColumns(containerKey, articlesKey, options);

        } else {
          $(adContainer).remove();
        }

      });

    } else refreshColumns(containerKey, articlesKey, options);

  } else if (index < iframes.length - 1) {
    reloadIFrame(iframes, index + 1, options);
  } else {
    refreshColumns(containerKey, articlesKey, options);
  }

}


function loadIFrame(iframes, index, options) {

  var iframe = getIframe($(iframes[index]).attr('src'));

  var position = $(iframes[index]).data('position');
  var containerKey = $(iframes[index]).data('container');
  var articlesKey = $(iframes[index]).data('container-articles');
  var bannerClass = $(iframes[index]).data('banner-class');
  var containerClass = $(iframes[index]).data('container-class');
  var articles = $(articlesKey);
  // var adCheckLimit = $(options).data('debug')?-1:0
  var adCheckLimit = 0;



  if (articles.length > position) {
    var article = articles[position];
    var adContainer = createAdContainer(article, bannerClass, containerClass, iframe);

    $(iframe).load(function () {

      if (($(iframe).contents().text().indexOf('window.asm_start_player') > -1) || $(iframe).contents().text().indexOf('window.asm_obaid') >= adCheckLimit) {

        var imgBanner = $(iframe).contents().find('img.asmbannerimg');
        if (imgBanner != null) {
          $(iframe).width($(imgBanner).width());
          $(iframe).height($(imgBanner).height());
        }

        $(iframe).contents().find('head').append(getCssLink());
        adaptMainContainer(article);
        $(adContainer).removeClass('ad_hidden');
        if ($(adContainer).hasClass('start_media_rectangle_2')) {
          $('.unniversimed-container .newest-articles .articleContent[data-article-index=5]').hide();
        }

        $(iframes[index]).removeAttr('needLoad');
        $(iframe).contents().find('head').find('script[src*="adscript"]').remove();
        $(iframe).contents().find('head').find('script:contains(asmgetref)').remove();
        $(iframe).contents().find('body').find('script[src*="pageview"]').remove();
        $(iframe).contents().find('body').find('script:contains(window.asm_pageview)').remove();


        iframesCache[$(options).find(iframes[index]).index()] = $(iframe).contents().find('html');

      } else {
        $(adContainer).remove();
      }

      if (iframes[index + 1]) {
        loadIFrame(iframes, index + 1, options);
      } else refreshColumns(containerKey, articlesKey, options);
    });

  } else refreshColumns(containerKey, articlesKey, options);
}

function refreshColumns(containerKey, articlesKey, options) {

  var count = 0;

  $(containerKey + ' .clearfix').remove();
  $(containerKey + ' ' + articlesKey + '[class*="col-"]').each(function () {
    var element = $(this);
    if (!element.hasClass('hidden')) {
      var found = false;
      $.each(element.attr('class').split(' '), function (index, className) {
        if (className.startsWith('col') && !found) {
          var classElements = className.split('-');
          if (classElements.length > 2) {
            count += parseInt(classElements[2]);
            if (count >= 12) {
              element.addClass('add-clearfix');
              count = 0;
            }
          }
          found = true;
        }
      });
    }
  });

  $('<div class="clearfix"></div>').insertAfter(articlesKey + '.add-clearfix');
  $(articlesKey + '.add-clearfix').removeClass('add-clearfix');

  var limit = $(options).data('limit');
  var articles = $(containerKey + ' ' + articlesKey);

  if (limit != null && articles != null && limit > 0 && articles.length > limit) {
    $(articles).each(function (index) {
      if (index >= limit) {
        $(this).remove();
      }
    });
  }

}

function adaptMainContainer(mainContainer) {
  if ($(mainContainer).data('big-version') != null && !$(mainContainer).hasClass('hidden')) {
    var articleId = $(mainContainer).data('big-version');
    var smallElement = $('.article[data-small-version=' + articleId + ']');
    if (smallElement.length > 0) {
      $(mainContainer).addClass('hidden');
      $(smallElement).removeClass('hidden');
    }

  }
}

function createAdContainer(mainContainer, bannerClass, containerClass, iframe) {

  var colContainer = document.createElement("div");
  $(colContainer).addClass('ad_hidden ' + containerClass);

  var adContainer = document.createElement("div");
  $(adContainer).addClass(bannerClass);
  $(adContainer).appendTo(colContainer);
  iframe.appendTo(adContainer);

  $(colContainer).insertBefore(mainContainer);
  return colContainer;
}

function getCssLink() {

  var iframeCss = window.location.protocol + '//' + window.location.hostname;
  if (window.location.port.length > 0) {
    iframeCss += ":" + window.location.port;
  }
  iframeCss += '/custom/css/ads_iframes.css';

  var cssLink = document.createElement("link");
  cssLink.href = iframeCss
  cssLink.rel = "stylesheet";
  cssLink.type = "text/css";
  return cssLink;

}

function checkSliderAd(element) {
  if (!element.hasClass('showed')) {
    element.parent('.carousel-item').removeClass('carousel-item');
  }
}
