/* csRegister.js */
var selector = "form.form-register";
var popoverSelector = ".webui-popover-password-popover";

function initPopoverValidation() {
    $(selector + ' .password').webuiPopover({ url: '#validationData', placement: 'auto-right', style: 'password-popover', trigger: 'manual' });
    $(selector + " button[type=button]").prop("disabled", true);
}

$(document).ready(function() {
    'use strict';

    /****************
     *
     * Register Validation
     *
     ****************/
    initPopoverValidation();

    $(document).on('click', '.registration form.form-register-newsletters button.submitNewsletterOptionsFormTrigger', function(e){
        if ($('.newsletters_page .csPreloader').length > 0) {
            $('.newsletters_page .csPreloader').toggle();
        }
        var values = "";
        $('.registration form.form-register-newsletters input[type="checkbox"]:checked').each(function() {
            values += $(this).val() + ",";
        });
        $('.registration form.form-register-newsletters input.registration_newsletteroptions_hidden').val(values);
        $('.registration form.form-register-newsletters input[type="submit"].newsletteroptions_btn').click();
    });

    $(document).on('focusin', selector + ' .password', function(e) {
        $(selector + ' .password').webuiPopover('show');
    });

    $(document).on('focusout', selector + ' .password', function(e) {
        $(selector + ' .password').webuiPopover('hide');
    });

    $(document).on('keyup', selector + ' .password', function(e) {

        var pass = $(selector + ' .password').val();
        var username = $(selector + ' .username').val();
        var oldpassword = $(selector + ' .oldpassword').val();

        var validAll = true;

        cleanIcons('size');
        if (pass.length >= 8) {
            setValid('size');
        } else {
            setFail('size');
            validAll = false;
        }

        var validChars = 0;
        var uppercase = new RegExp('.*[A-Z]{1,}.*');
        if (uppercase.test(pass)) {
            validChars++;
        }

        var lowercase = new RegExp('.*[a-z]{1,}.*');
        if (lowercase.test(pass)) {
            validChars++;
        }

        var digits = new RegExp('.*[0-9]{1,}.*');
        if (digits.test(pass)) {
            validChars++;
        }

        var specials = new RegExp('.*[~!@#$%^&*_\\-+=`|(){}[\\]:;"\'<>,.?/ ]{1,}.*');
        if (specials.test(pass)) {
            validChars++;
        }

        cleanIcons('chars');
        if (validChars >= 2) {
            setValid('chars');
        } else {
            setFail('chars');
            validAll = false;
        }

        cleanIcons('username');
        var index = username.indexOf("@");
        var subUsername = "";

        if (index != -1) {
            subUsername = username.substring(0, index);
        }

        if (pass == '' || (pass != '' && pass.toLowerCase().indexOf(subUsername.toLowerCase()) != -1)) {
            setFail('username');
            validAll = false;
        } else {
            setValid('username');
        }

        cleanIcons('oldpassword');
        if (pass == '' || (pass != '' && oldpassword !== undefined && oldpassword != '' && pass.toLowerCase().indexOf(oldpassword.toLowerCase()) != -1)) {
            setFail('oldpassword');
            validAll = false;
        } else {
            setValid('oldpassword');
        }

        var quality = $(popoverSelector + ' .quality');
        quality.html('');
        quality.removeClass();
        quality.addClass('quality');

        cleanIcons('warning');
        if (pass != '') {
            result = zxcvbn(pass);
            quality.addClass('quality' + result.score);
            var qualityLabel;
            switch (result.score) {
                case 0:
                    qualityLabel = 'Niedrig';
                    break;
                case 1:
                    qualityLabel = 'Niedrig-Mittel';
                    break;
                case 2:
                    qualityLabel = 'Mittel';
                    break;
                case 3:
                    qualityLabel = 'Mittel-Hoch';
                    break;
                case 4:
                    qualityLabel = 'Hoch';
                    break;
            }
            $(popoverSelector + ' .quality').html(qualityLabel);

            /*if (result.feedback != null && result.feedback.warning != null && result.feedback.warning != '') {
                setFail('warning');
                validAll = false;
            } else {
                setValid('warning');
            }*/
        } else {
            setFail('warning');
        }

        if (validAll) {
            $("button[type=button].submitRegisterFormTrigger").prop("disabled", false);
        } else {
            $("button[type=button].submitRegisterFormTrigger").prop("disabled", true);
        }
    });

    function cleanIcons(classSelector) {
        $(popoverSelector + ' i.' + classSelector).removeClass('fa-times');
        $(popoverSelector + ' i.' + classSelector).removeClass('fa-check');
    }

    function setValid(classSelector) {
        $(popoverSelector + ' i.' + classSelector).addClass('fa-check');
        $('.passwordValidation .' + classSelector).addClass('hidden');
    }

    function setFail(classSelector) {
        $(popoverSelector + ' i.' + classSelector).addClass('fa-times');
        $('.passwordValidation .' + classSelector).removeClass('hidden');
    }

    /****************
     *
     * Register Form
     *
     ****************/

    /*var emailSwitchTrigger = '.form-register input[type=\'email\'].email';

    $(document).on('blur', emailSwitchTrigger, function(e) {

        $('form.form-register input[type=\'hidden\'].event').val('');

        window.globalVar = false;
        $updateExtraFormButton = $('.form-register .updateExtraForm');
        $(window).keyup(function(e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 9) {
                $('form.form-register input[type=\'hidden\'].event').val('tab');
                $updateExtraFormButton.click();
            }
        });

        if ($updateExtraFormButton.length > 0) {
            $updateExtraFormButton.click();
        }
    });

    if ($('form.form-register .extraFormUpdated').length > 0) {
        $('form.form-register input.email2').focus();
    } */

    /****************
     *
     * Contact Form
     *
     ****************/

    $(document).on('change', '.contact select.category', function(e) {
        $('.contact .info').show();

        if ($(".contact .csRow #brandpermission").length > 0 && $(this).val() == "rights") {
            $('.contact .csRow #brandpermission').closest(".csRow").show();
        } else {
            $('.contact .csRow #brandpermission').closest(".csRow").hide();
        }
    });
});

function hidePassword() {
    if ($('form.form-register .passwordWrapper').length > 0) {
        $('form.form-register .passwordWrapper').hide();
    }
}

function removeFixedWrapper() {
    if ($('form.form-register .fixedWrapper').length > 0) {
        $('form.form-register .fixedWrapper').remove();
    }
}

function submitRegister() {
    if ($('form.form-register .submitRegister').length > 0) {
        $('form.form-register .submitRegister').click();
    }
}