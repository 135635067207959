$(document).ready(function() {
  'use strict';


  $(document).on('change', '.typeFilter #typeFilter', function(e) {

    $('.dynamicFilter .article-wrapper').hide();

    let activeFilters = $('.keywordFilter .keyword-wrapper.selected');

    if (activeFilters.length > 0) {

      activeFilters.each(function(e, v) {
        $('.dynamicFilter .article-wrapper[data-keywords*="' + $(v).find('.keyword').data('key') + '"]').show();
      });

    } else {
      $('.dynamicFilter .article-wrapper').show();
    }


    let val = $(e.currentTarget).val();

    val = val.toLowerCase();
    // $('.dynamicFilter .article-wrapper').show();

    if (val !== 'all') {    
      val = val.substring(0, val.length - 1);
      $('.dynamicFilter .article-wrapper:not([data-type*="' + val + '"])').hide();
    }

   

    $('.select-wrapper').removeClass('open');

  });


  $(document).on('click', '.keywordFilter .keyword-wrapper', function(e) {

    if (!$(e.currentTarget).hasClass('selected')) {
      $(e.currentTarget).addClass('selected');
    } else {
      $(e.currentTarget).removeClass('selected');
    }

  
    let activeFilters = $('.keywordFilter .keyword-wrapper.selected');

    if (activeFilters.length > 0) {
      $('.dynamicFilter .article-wrapper').hide();

      activeFilters.each(function(e, v) {
        $('.dynamicFilter .article-wrapper[data-keywords*="' + $(v).find('.keyword').data('key') + '"]').show();
        //$('.dynamicFilter .article-wrapper:not([data-keywords*="' + $(v).find('.keyword').data('key') + '"])').hide();
      });

    } else {
      $('.dynamicFilter .article-wrapper').show();
    }


    let val = $('.typeFilter #typeFilter').val();

    val = val.toLowerCase();

    if (val !== 'all') {
      val = val.substring(0, val.length - 1);

      // $('.dynamicFilter .article-wrapper[data-type*="' + val + '"]').show();
      $('.dynamicFilter .article-wrapper:not([data-type*="' + val + '"])').hide();
    }
    
  });

});