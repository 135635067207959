var ssoSites = ['159.69.1.27:8192',
  '159.69.1.27:8190',
  '159.69.1.27:8292',
  '159.69.1.27:8290',
  'allgemeineplus.at',
  'med-diplom.universimed.spsts.de',
  'med-diplom.universimed-live.spsts.de',
  'universimed.spsts.de',
  'universimed.com',
  'www.universimed.com',
  'med-diplom.at',
  'www.med-diplom.at',
  'www.allgemeine-plus.at',
  'live-med-diplom-ch.universimed.spsts.de',
  'med-diplom-ch.universimed.spsts.de',
  'med-diplom.ch',
  'www.med-diplom.ch',
];

var blankSites = [
  'allgemeineplus.at',
  'allgemeine-plus.at'
];


var reftokenSites = [
  'dgim2022.universimed.com'
];

$(document).ready(function () {

  $(document).on('click', 'a', function (event) {


    if ($('.userdata').length > 0 && reftokenSites.some(some, this)) {

        $(this).attr('href', $(this).attr('href') + '/sso/' + $('.userdata').data('user') );

    } else if ($('.userdata').length > 0 && ssoSites.some(some, this)) {
      if ($(this).attr('href').includes('?')) {
        if ($(this).attr('href').includes('universimed.adspirit')) {
          $(this).attr('href', $(this).attr('href') + '%3Fpayload=' + $('.userdata').data('user'));
        } else {
          $(this).attr('href', $(this).attr('href') + '&payload=' + $('.userdata').data('user'));
        }
      } else {
        $(this).attr('href', $(this).attr('href') + '?payload=' + $('.userdata').data('user'));
      }
    }

    if (blankSites.some(some, this)) {
      $(this).attr('target', '_blank');
    }


  });

  setTimeout(function () {
    if ($('select.rubrics_select').length > 0) {
      $('select.rubrics_select').dropdown('toggle');
      $('select.rubrics_select').dropdown('toggle')
    }
  }, 500);


  var uri = window.location.toString();
	if (uri.indexOf("payload=") > 0) {
	    var clean_uri = uri.substring(0, uri.indexOf("?"));
	    window.history.replaceState({}, document.title, clean_uri);
	}


});

function some(x) {
  return $(this).attr('href') && $(this).attr('href').includes(x);
}
