$(document).on('click', '.csArticle-page_wrapper img', function (e) {

  if ($(this).parents('.mdb-lightbox').length === 0) {
    if ($(this).data("new-window")) {
      window.open($(this).attr('src'));
    } else {
      $('#imgZoom img').attr('src', '');
      $('#imgZoom img').attr('src', $(this).attr('src'));


      $('#imgZoom').modal('show');
    }
  }

});

$(document).on('click', '.csSmartNav .button-close', function (e) {
  $("#mp-trigger").click();
});

$(document).on('click', '.load-more_btn', function (e) {

  if ($(this).closest('.js-result-replace').length > 0) {
    $(this).closest('.js-result-replace').data('target-url', $(this).closest('.js-result-replace').data('target-url').replace(/theme\d\.page=\d+/,''));
  }
  
  $(this).addClass('loading');
  $(this).siblings('.load-more_btn-submit').click();
});


var loadingPage = false;

/*$(window).on('scroll', function() {
  if (!loadingPage && $(window).scrollTop() >= ($(document).height() - $(window).height() - $('footer').height() + 50) && $('input.load-more_btn').length > 0) {
      $('input.load-more_btn').click();
  }
});*/

function calculateReadingTime() {
  // Calculate reading time
  var wordsPerMinute = 320;

  var words = 0;

  if ($('.html-string').length > 0) {
    words = $('.article-intro').text().split(' ').length;
    words += $('.article-content').text().split(' ').length;
  } else {
    words = $('.csArticle-page_wrapper').text().split(' ').length;
  }

  $('.min-count').text(Math.ceil(words / wordsPerMinute));
  $('.time-wrapper').removeClass('hidden');

}

$(document).ready(function () {
  /* $("p:contains('Lesen sie auch:')").remove();*/
  $("p:contains('Lesen sie auch:')").find('a').each(function () {
    $(this).attr('href', $(this).attr('href').replace(/^https?:\/\/.*?\//g, window.location.origin + '/'));
  });

  calculateReadingTime();


  $('.parseHtml').each(function () {
    var log = $(this);
    var str = $(this).text();
    var html = $.parseHTML(str);
    log.html(html);
  });
});

