
$(document).ready(function () {
  $('.embebThumbnail').each(function () {
    vimeoLoadingThumb($(this).data('videosrc'));
  })
});

function vimeoLoadingThumb(videoSrc) {
  var idIndex = videoSrc.lastIndexOf('/');
  var id;

  if (idIndex > -1) {
    id = videoSrc.substring(idIndex + 1);
  }
  if (videoSrc.indexOf('youtube') != -1) {
    var url = 'https://img.youtube.com/vi/' + id + '/0.jpg'
    var data = [{
      id: id,
      thumbnail_medium: url
    }];

    showThumb(data);

  } else if (videoSrc.indexOf('vimeo') != -1) {
    var url = "http://vimeo.com/api/v2/video/" + id + ".json?callback=showThumb";

    var script = document.createElement('script');
    script.src = url;

    $('*[data-videosrc$="' + id + '"]').before(script);

  }

}


function showThumb(data) {
  var img = $('*[data-videosrc$="' + data[0].id + '"]');

  if (img.length > 0) {
    img.attr('src', data[0].thumbnail_medium);
  }
  
}

