$(document).ready(function () {


  $(document).on('change', '.nejm_list .theme-list-form .nejm-datefilterFrom-select', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.nejm_list .theme-list-form .nejms_list_btn').click();
  });

  $(document).on('change', '.nejm_list .theme-list-form .nejm-datefilterTo-select', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.nejm_list .theme-list-form .nejms_list_btn').click();
  });

  $(document).on('focusout', '.nejm_list .theme-list-form .nejm-searchTerm-value', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.nejm_list .theme-list-form .nejms_list_btn').click();
  });

  if ($('.nejm_list').length > 0) {
    $('.breadcrumb').remove();
  }

});
