var viewType = 'grid';
var cookienLand = 'OCC_universimed_portal_land';
var cookieRubrics = 'OCC_universimed_portal_rubrics';
var loadRubrics = true;
var reloadBanner = 1;

function updateView() {
  if (viewType == 'list') {
    $('.theme_list .theme-list-form .buttons .show_list').addClass('selected');

    $('.theme_list .articles .article').removeClass('col-md-4');
    $('.theme_list .articles .article').removeClass('grid');
    $('.theme_list .articles .article').removeClass('p-0');
    $('.theme_list .articles .article').addClass('col-md-12');
    $('.theme_list .articles .article').addClass('list');

    $('.theme_list .articles .article .image').removeClass('col-md-12');
    $('.theme_list .articles .article .image').addClass('col-md-4');

    $('.theme_list .articles .article .text').removeClass('col-md-12');
    $('.theme_list .articles .article .text').addClass('col-md-8');
  } else {
    $('.theme_list .theme-list-form .buttons .show_grid').addClass('selected');

    $('.theme_list .articles .article').removeClass('col-md-12');
    $('.theme_list .articles .article').removeClass('list');
    $('.theme_list .articles .article').addClass('col-md-4');
    $('.theme_list .articles .article').addClass('grid');
    $('.theme_list .articles .article').addClass('p-0');

    $('.theme_list .articles .articlet .image').removeClass('col-md-4');
    $('.theme_list .articles .article .image').addClass('col-md-12');

    $('.theme_list .articles .article .text').removeClass('col-md-8');
    $('.theme_list .articles .article .text').addClass('col-md-12');
  }
}

function getLandRubrics(land) {
  return $.cookie(cookieRubrics + '_' + land);
}

function setCurrentLand(formSelector, selectedLand) {
  var land = $(selectedLand).data('land');
  var url = $(location).attr('href');
  var index = url.indexOf('?');
  if (land) {
    saveLangCookie(land);

    var newRubrics = getLandRubrics(land);
    newRubrics = newRubrics ? newRubrics : '';

    updateFloatMenuRubrics(newRubrics);
    saveRubricsCookie(newRubrics);

    if ($('.theme_list form input.theme_list_rubrics_hidden').length > 0) {
      $('.theme_list form input.theme_list_rubrics_hidden').val('');
    }
    if (index > -1) {
      url = url.substring(0, index);
    }


    if ($('form.' + formSelector + ' input[name=land]') && $('form.' + formSelector + ' input[name=land]').val()) {
      var pattern = '\/' + $('form.' + formSelector + ' input[name=land]').val() + '(\/|$)';
      var re = new RegExp(pattern);

      url = url.replace(re, '/' + land + '/');
    }

    $('form.' + formSelector + ' .switch_land_input').val(land);
    $('form.' + formSelector + ' .switch_land_btn').click();

    setTimeout(function () { $(location).attr('href', url); }, 500);
  }
}

function saveLangCookie(land) {
  if (typeof cookieTool !== 'undefined') {
    cookieTool.if("performance", function () {
      saveLangCookieContent(land);
    });
  }
}

function saveLangCookieContent(land) {
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + 10000000 * 36000;
  now.setTime(expireTime);
  document.cookie = cookienLand + '=' + land + ';expires=' + now.toGMTString() + ';path=/';
}

function saveRubricsCookie(rubrics) {
  if (typeof cookieTool !== 'undefined') {
    cookieTool.if("performance", function () {
      saveRubricsCookieContent(rubrics);
    });
  }
}

function saveRubricsCookieContent(rubrics) {
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + 10000000 * 36000;
  now.setTime(expireTime);

  var land = $.cookie(cookienLand);
  document.cookie = cookieRubrics + '_' + land + '=' + rubrics + ';expires=' + now.toGMTString() + ';path=/';
  document.cookie = cookieRubrics + '=' + rubrics + ';expires=' + now.toGMTString() + ';path=/';
}

function changeFachthemenSelects() {
  var checked = $('.theme_list .rubrics_selected li span');
  selectedRubrics = checked.length;

  if (selectedRubrics > 0) {
    var dropdown = $('.theme_list .rubrics_select input.dropdown-toggle');
    dropdown.val('');
    dropdown.hide();
    dropdown.after('<div class="custom_container"><span class="custom_label">Fachthemen:</span><span class="custom_label more">' + selectedRubrics + '</span></div>');
  }

}

function updateRubricsCheckboxClass() {
  $('.theme_list .rubrics_select option').each(function () {
    if ($(this).hasClass("hideCheckbox")) {

      var optionIndex = $(this).index();
      var element = $('.theme_list .rubrics_select li')[optionIndex];
      $(element).addClass("hideCheckbox");
    }
  });
}

function updateFloatMenuRubrics(newValue) {
  $('.fachthemen.floatMenu input').each(function () {
    var currentValue = $(this).val();
    var checked = (newValue.indexOf(currentValue) > -1);
    $(this).prop('checked', checked);
  });
}

function getMessageFocus() {
  var messages = $('.registration .list-group')
  if (messages.length > 0) {
    window.scrollTo(0, 0);
  }
}

const outsider = document.getElementById('fach-list');
const subOutsider = $('#cluster-innere-medizin .fach-list')[0];
const distance = 100;

function scrollMenuLft() {
  outsider.scrollBy({
    left: -distance,
    behavior: 'smooth'
  });
}

function scrollMenuRight() {
  outsider.scrollBy({
    left: distance,
    behavior: 'smooth'
  });
}

function scrollSubmenuLft() {
  subOutsider.scrollBy({
    left: -distance,
    behavior: 'smooth'
  });
}

function scrollSubmenuRight() {
  subOutsider.scrollBy({
    left: distance,
    behavior: 'smooth'
  });
}

function openCurrentMenu(scroll) {
   // Active menu
   var currentMenu = $('[data-click-handler*="' + window.location.search + '"]');


   currentMenu.addClass('current');

   var parents = currentMenu.parents('.fachthemen-submenu');

   if (parents.length > 0) {
     var cluster = parents[0];

     $('[data-id="' +cluster.id + '"]').addClass('current');

     if (scroll) {
      $('[data-id="' +cluster.id + '"]').parents('.fach-list').animate({
        scrollLeft: currentMenu.offset().left -  $('[data-id="' +cluster.id + '"]').parents('.fach-list').offset().left
        }, 100);
    }

     $(cluster).css("display", "flex");

   }


   if (scroll && currentMenu && currentMenu.length > 0) {
    $(currentMenu).parents('.fach-list').animate({
      scrollLeft: currentMenu.offset().left - $(currentMenu).parents('.fach-list').offset().left
    }, 100);
   }

}

function removeInvalidParamsNewest() {
  $(".newest-articles form input[name='rubrics']").remove();

  if ( $(".newest-articles div[data-target-url]").length > 0) {
    $(".newest-articles div[data-target-url]").data('target-url', $(".newest-articles div[data-target-url]").data('target-url').replace(/rubrics\=.+?(\&|$)/g,''));
    $(".newest-articles div[data-target-url]").data('target-url', $(".newest-articles div[data-target-url]").data('target-url').replace(/theme\d+\.page\=.+?(\&|$)/g,''));
    $(".newest-articles div[data-target-url]").data('target-url', $(".newest-articles div[data-target-url]").data('target-url') + '&' + $("#newest-page").attr('name') + '=' + $("#newest-page").val());
  }

}

function saveHistoryCookie(history) {
  $.cookie('history', history, { path: '/' });
}

function getHistoryCookie() {
  return $.cookie('history');
}

function goToHistory() {
  if (getHistoryCookie()) {
    window.location.href = getHistoryCookie();
  } else {
    window.history.back();
  }
}

function disableEmptyWidgets() {
  for (var i = 0; i < $('.sidebar-widget').length; i++) {
    if (($('.sidebar-widget.sorting-' + i).find('ins').length > 0 && $('.sidebar-widget.sorting-' + i).find('ins').children('div').length <= 0) || $('.sidebar-widget.sorting-' + i).children().length <= 0) {
      $('.sidebar-widget.sorting-' + i).removeClass('sorting-' + i);
    }
  }

  var newIndex = 0

  for (var i = 0; i < $('.sidebar-widget').length; i++) {
    if ($($('.sidebar-widget')[i]).is('[class*="sorting-"]')) {
      $($('.sidebar-widget')[i]).removeClass('sorting-' + i);
      $($('.sidebar-widget')[i]).addClass('sorting-' + newIndex);
      newIndex ++;
    }
  }
}

function mixSidebarWidgetsInMobile() {
  if (window.innerWidth <= 1024 && (($('.sidebar-wrapper') && $('.sidebar-wrapper').children().length > 0) || ($('.sidebar') && $('.sidebar').children().length > 0))) {
    setTimeout(function () { moveSidebarWidgetsToMainContent(); }, 1900);

  }

  if (window.innerWidth > 1024 && (($('.sidebar-wrapper') && $('.content-c-wrapper') && $('.content-c-wrapper').find('.sidebar-widget').length > 0))) {
    moveSidebarWidgetsToSidebar();
    sortSidebarWidgets();
  }

  if (window.innerWidth > 1024 && (($('.sidebar') && $('.article-content-main') && $('.article-content-main').find('.sidebar-widget').length > 0))) {
    moveSidebarWidgetsToSidebar();
    sortSidebarWidgets();
  }
}

function moveSidebarWidgetsToMainContent() {
  var counter = 0;
  if ($('.main-content-widget') && $('.sidebar-widget')) {
    for (var i = 0; i < $('.main-content-widget').length; i++) {
      if ($('.sidebar-widget.sorting-' + i)) {
        if (i == 0) {
          $('.sidebar-widget.sorting-' + i).insertBefore('.main-content-widget.sorting-' + i);
          counter++;
        } else {
          if ($('.sidebar-widget.sorting-' + i).find('.configurable-widget').length > 0) {
            if (counter < $('.h4-responsive.clear').length) {
              if ($('.h4-responsive.clear')[counter].closest('.literatur') == null) {
                $('.sidebar-widget.sorting-' + i).insertBefore($('.h4-responsive.clear')[counter]);
                counter++;
              } else {
                $('.sidebar-widget.sorting-' + i).insertBefore('.main-content-widget.sorting-' + i);
              }
            } else {
              $('.sidebar-widget.sorting-' + i).insertBefore('.main-content-widget.sorting-' + i);
            }
          } else {
            $('.sidebar-widget.sorting-' + i).insertBefore('.main-content-widget.sorting-' + i);
          }
        }
      }
    }
  }
}

function moveSidebarWidgetsToSidebar() {
  if ($('.content-c-wrapper') && $('.sidebar-wrapper') && $('.sidebar-widget')) {
    for (var i = 0; i < $('.sidebar-widget').length; i++) {
      if ($('.sidebar-widget.sorting-' + i)) {
          $('.content-c-wrapper>.sidebar-widget.sorting-' + i).appendTo( $('.sidebar-wrapper'));
      }
    }
  }
  if ($('.article-content-main') && $('.sidebar') && $('.sidebar-widget')) {
    for (var i = 0; i < $('.sidebar-widget').length; i++) {
      if ($('.sidebar-widget.sorting-' + i)) {
          $('.article-content-main>.sidebar-widget.sorting-' + i).appendTo( $('.sidebar'));
      }
    }
  }
}

function sortSidebarWidgets() {
  if (($('.sidebar-wrapper') ||$('.sidebar')) && $('.sidebar-widget')) {
    for (var i = 0; i < $('.sidebar-widget').length; i++) {
      var currentWidget = $('.sidebar-widget.sorting-' + i);
      var nextWidget = $('.sidebar-widget.sorting-' + (i + 1));

      if (currentWidget.length && nextWidget.length) {
          currentWidget.insertBefore(nextWidget);
      }
    }
  }
}

// function sortSidebarWidgets() {
//   if (($('.sidebar-wrapper') ||$('.sidebar')) && $('.sidebar-widget')) {
//     for (var i = -1; i < $('.sidebar-widget').length; i++) {
//       if ($('.sidebar-widget.sorting-' + i)) {
//         var j = i+1;
//         $('.sidebar-widget.sorting-' + i).insertBefore('.sidebar-widget.sorting-' + j);
//       }
//     }
//   }
// }

function moveRelatedRubricArticle() {
  if (window.innerWidth <= 1024 && $('.related-rubric-articles') && ($('.csArticle-page_wrapper') && $('.csArticle-page_wrapper').children().length > 2)) {
    var count = 0;
    for (var i = 0; i < $('.csArticle-page_wrapper').children().length; i++) {
      if ($('.csArticle-page_wrapper').children().get(i).outerHTML.startsWith('<p')) {
        count++;

        if (count == 3) {
          $('.related-rubric-articles').insertAfter($('.csArticle-page_wrapper').children().get(i));
        }
      }
    }
  }
}





var loadSelectLabel = true;
var selectedRubrics = 0;
$(document).ready(function () {

  if (!window.location.href.includes("/article/") && !window.location.href.includes("/kurs/") && !window.location.href.includes("/kursinformation/")) {

    saveHistoryCookie(window.location.href);
  }

  if (($('.sidebar-wrapper') && $('.sidebar-wrapper').children().length > 0) || ($('.sidebar') && $('.sidebar').children().length > 0)) {
    disableEmptyWidgets();
  }

  if ($('.mobile-menu-btn').is(":visible")) {
    mixSidebarWidgetsInMobile();

    if (window.location.href.includes("/article/")) {
      moveRelatedRubricArticle();
    }
  }

  openCurrentMenu(true);
  removeInvalidParamsNewest();

  if (window.location.search.includes('cluster')) {
    sublistClick('cluster-' + window.location.search.split('=')[1]);
  }

  /*if (window.location.href.includes('contenu-en-francais')) {
    $('.btn-lang-fr').addClass('active');
    $('.btn-lang-de').removeClass('active');
  } else {
    $('.btn-lang-de').addClass('active');
    $('.btn-lang-fr').removeClass('active');
  }*/

  $(document).on('click', '.mobile-menu-btn', function (event) {

    $('.mobile-menu').toggle();
    $('.mobile-menu-btn .openmenu').toggle();
    $('.mobile-menu-btn .closemenu').toggle();

    if ($('.mobile-menu').is(":visible")) {
      $('html, body').attr('style', 'overflow: hidden !important; height: 100%');
    } else {
      $('html, body').attr('style', 'overflow: auto; height: auto');
    }

  });

  $('.asm_async_creative').on('DOMSubtreeModified', function(){
    var newIndex = 0;

    for (var i = 0; i < $('.sidebar-widget').length; i++) {
      if ($($('.sidebar-widget')[i]).find('ins').length > 0 && $($('.sidebar-widget')[i]).find('ins').children('div').length > 0) {
        $($('.sidebar-widget')[i]).addClass('sorting-' + newIndex);
        newIndex ++;
      }
    }

  });


  $(document).on('click', '#next-fach-menu', function (event) {
    scrollMenuLft();
  });

  $(document).on('click', '#prev-fach-menu', function (event) {
    scrollMenuRight();
  });

  $(document).on('click', '#next-fach-menucluster-innere-medizin', function (event) {
    scrollSubmenuLft();
  });

  $(document).on('click', '#prev-fach-menucluster-innere-medizin', function (event) {
    scrollSubmenuRight();
  });

  $(document).on('click', 'a', function (event) {
    if ($(this).attr('href') && $(this).attr('href').indexOf('elearning.med-diplom.com') > -1 || $(this).attr('href') && $(this).attr('href').indexOf('med-diplom.ispringlearn.com') > -1) {

      var course = $(this).attr('href');
      var target = $(this).attr('target');

      event.preventDefault();
      event.stopPropagation();

      var arguments = window.location.href.split("/");
      console.log(arguments);
      var url = arguments[0] + "//" + arguments[2] + "/_ispring_course_/";

      if (arguments.length > 4) {
        let idSplit = arguments[arguments.length - 1].split('-');
        url += idSplit[idSplit.length - 1];
      }

      var possible = false;
      var loginUrl = "";
      $.ajax({
        crossDomain: true,
        url: url,
        async: false,
        success: function (data) {
          if (data.url) {
            loginUrl = data.url;
          }
        }
      });

      if (loginUrl) {
        w = window.open(loginUrl);
        setTimeout(function () {
          w.close();
          if (target && target == '_blank') {
            window.open(course);
          } else {
            window.location.href = course;
          }
        }, 1000);
      }
    };
  });

  if (location.pathname.indexOf('userkonto') > -1 && location.hash.indexOf('tab2') > -1) {

    var tabElement = $('.csTabs .nav-tabs a[href="' + location.hash + '"]');
    if (tabElement.length > 0) {
      $(tabElement).click();
    }
  }

  $(document).on('click', 'a[href*="showCookieTool"]', function (event) {
    event.stopPropagation();
    event.preventDefault();
    // showCookieTool(event.originalEvent);
    UC_UI.showFirstLayer();
  });

  $(document).on('animationstart', 'input', function (e) {
  });

  var checker = $('.checkCookie.switchLandChecker');
  if (checker.length > 0) {
    setCurrentLand('switch-land-selector-form', checker);
  }

  $('#slider-article-slide .slider-articles-teaser-fachthemen').css('marginRight', (($('.carousel-inner-fachthemen').width() / 0.875) * 0.125) / 2 + 'px');
  $('#slider-article-slide .carousel-indicators-fachthemen').css('margin-bottom', ($('#slider-article-slide .slider-articles-teaser-fachthemen').height() + parseInt($('#slider-article-slide .slider-articles-teaser-fachthemen').css('padding-top')) * 2) + 'px');

  $(window).resize(function () {
    $('#slider-article-slide .slider-articles-teaser-fachthemen').css('marginRight', (($('.carousel-inner-fachthemen').width() / 0.875) * 0.125) / 2 + 'px');
    $('#slider-article-slide .carousel-indicators-fachthemen').css('margin-bottom', ($('#slider-article-slide .slider-articles-teaser-fachthemen').height() + parseInt($('#slider-article-slide .slider-articles-teaser-fachthemen').css('padding-top')) * 2) + 'px')

    mixSidebarWidgetsInMobile();
  });

  //updateRubricsCheckboxClass();

  // Grid as default
  $('.theme_list .theme-list-form .buttons .show_grid').addClass('selected');
  $('.theme_list .articles .article').removeClass('col-md-12');
  $('.theme_list .articles .article').removeClass('list');
  $('.theme_list .articles .article').addClass('col-md-4');
  $('.theme_list .articles .article').addClass('grid');
  $('.theme_list .articles .article').addClass('p-0');

  $('.theme_list .articles .articlet .image').removeClass('col-md-4');
  $('.theme_list .articles .article .image').addClass('col-md-12');

  $('.theme_list .articles .article .text').removeClass('col-md-8');
  $('.theme_list .articles .article .text').addClass('col-md-12');

  $(document).on('click', '.theme_list .theme-list-form .buttons .show_grid', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).addClass('selected');
    $('.theme_list .theme-list-form .buttons .show_list').removeClass('selected');

    if (window.location.href.includes('at/dfp') || window.location.href.includes('ch/cme')) {
      $('.theme_list .article-list .article-wrapper').addClass('fx-flex-32');
      $('.theme_list .article-list .article-wrapper').removeClass('fx-flex-100');
      $('.theme_list .article-list .article-wrapper').removeClass('article-100');
      $('.theme_list .article-list .article-wrapper .articleElement').removeClass('fx-flex fx-flex-direction-row');
      $('.theme_list .article-list .article-wrapper .articleElement .top-teaser-list').removeClass('fx-flex fx-flex-32 mr-1');
      $('.theme_list .article-list .article-wrapper .articleElement .top-teaser-list .teaser-chips-wrapper').removeClass('teaser-chips-wrapper-list');
      $('.theme_list .article-list .article-wrapper .articleElement .data').addClass('fx-flex fx-flex-68 fx-flex-direction-column');
    } else {
      $('.theme_list .articles .article').removeClass('col-md-12');
      $('.theme_list .articles .article').removeClass('list');
      $('.theme_list .articles .article').addClass('col-md-4');
      $('.theme_list .articles .article').addClass('grid');
      $('.theme_list .articles .article').addClass('p-0');

      $('.theme_list .articles .articlet .image').removeClass('col-md-4');
      $('.theme_list .articles .article .image').addClass('col-md-12');

      $('.theme_list .articles .article .text').removeClass('col-md-8');
      $('.theme_list .articles .article .text').addClass('col-md-12');
    }

    viewType = 'grid';
  });

  $(document).on('click', '.theme_list .theme-list-form .buttons .show_list', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).addClass('selected');
    $('.theme_list .theme-list-form .buttons .show_grid').removeClass('selected');

    if (window.location.href.includes('at/dfp') || window.location.href.includes('ch/cme')) {
      $('.theme_list .article-list .article-wrapper').removeClass('fx-flex-32');
      $('.theme_list .article-list .article-wrapper').addClass('fx-flex-100');
      $('.theme_list .article-list .article-wrapper').addClass('article-100');
      $('.theme_list .article-list .article-wrapper .articleElement').addClass('fx-flex fx-flex-direction-row');
      $('.theme_list .article-list .article-wrapper .articleElement .top-teaser-list').addClass('fx-flex fx-flex-32 mr-1');
      $('.theme_list .article-list .article-wrapper .articleElement .top-teaser-list .teaser-chips-wrapper').addClass('teaser-chips-wrapper-list');
      $('.theme_list .article-list .article-wrapper .articleElement .data').addClass('fx-flex fx-flex-68 fx-flex-direction-column');
    } else {
      $('.theme_list .articles .article').removeClass('col-md-4');
      $('.theme_list .articles .article').removeClass('grid');
      $('.theme_list .articles .article').removeClass('p-0');
      $('.theme_list .articles .article').addClass('col-md-12');
      $('.theme_list .articles .article').addClass('list');

      $('.theme_list .articles .article .image').removeClass('col-md-12');
      $('.theme_list .articles .article .image').addClass('col-md-4');

      $('.theme_list .articles .article .text').removeClass('col-md-12');
      $('.theme_list .articles .article .text').addClass('col-md-8');
    }

    viewType = 'list';
  });

  $(document).on('click', '.theme_list .rubrics_select', function () {

    if (!$(this).closest('input.dropdown-toggle').val()) {
      $('.theme_list .rubrics_select input.dropdown-toggle').click();
    }

  });

  $(document).on('DOMNodeInserted', '.theme_list .rubrics_select.select-wrapper.dropdown .initialized', function (e) {
    if (loadSelectLabel) {
      changeFachthemenSelects();
      loadSelectLabel = false;
    }
  });

  $(document).on('change', '.epapers_list form select.epapers-select', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.epapers_list form input.epapers_list_btn').click();
  });

  $(document).on('change', '.epapers_list form select.epaper-filter-select', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.epapers_list form input.epaper-filter-value').val($(this).val());
    $('.epapers_list form input.epapers_list_btn').click();

  });

  $(document).on('change', '.epapers_list form select.epaper-datefilter-select', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.epapers_list form input.epaper-datefilter-value').val($(this).val());
    $('.epapers_list form input.epapers_list_btn').click();

  });

  $(document).on('DOMNodeInserted', '.epapers_list form select.epaper-filter-select.select-wrapper.dropdown .initialized', function (e) {
    var input = $(this).find('input.dropdown-toggle');
  });

  $(document).on('DOMNodeInserted', '.epapers_list form select.epaper-datefilter-select.select-wrapper.dropdown .initialized', function (e) {
    var input = $(this).find('input.dropdown-toggle');
  });

  $(document).on('change', '.theme_list form select.fachthemen-select', function (e) {
    e.preventDefault();
    e.stopPropagation();
  });

  $(document).on('change', '.theme_list form select.fachthemen-select.kursart', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.theme_list form input.theme_list_btn').click();
  });

  $(document).on('change', '.theme_list form select.fachthemen-select.sorting', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.theme_list form input.theme_list_btn').click();
  });

  $(document).on('change', '.theme_list form select.fachthemen-select.art', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.theme_list form input.theme_list_btn').click();
  });

  $(document).on('show.bs.dropdown', '.theme_list .rubrics_select.select-wrapper.dropdown', function (e) {
    $('.theme_list .closeButton').css('display', 'inline-block');
    updateRubricsCheckboxClass();
  });

  $(document).on('hidden.bs.dropdown', '.theme_list .rubrics_select.select-wrapper.dropdown', function (e) {

    $('.theme_list .closeButton').css('display', 'none');
    var selected = $('.theme_list .rubrics_select.select-wrapper.dropdown .initialized option:selected:enabled');
    var newValue = '';
    var paramRubricsIds = '';
    $(selected).each(function () {
      newValue += $(this).data('label') + ',';
      paramRubricsIds += $(this).val() + ',';
    });
    newValue = newValue.substring(0, newValue.length - 1);
    paramRubricsIds = paramRubricsIds.substring(0, paramRubricsIds.length - 1);

    $('.theme_list form input.theme_list_rubrics_hidden').val(newValue);

    if ($('.theme_list_event').length === 0) {
      if (loadRubrics) {
        var land = $.cookie(cookienLand);
        paramRubricsIds = getLandRubrics(land);
        paramRubricsIds = paramRubricsIds ? paramRubricsIds : '';
        loadRubrics = false;
      }

      updateFloatMenuRubrics(paramRubricsIds);
      saveRubricsCookie(paramRubricsIds);
    }

    $('.slider-list-form input.slider_list_rubrics_hidden').val(newValue);

    $('.slider-list-form input.slider_list_btn').click();

    var refresh = false;
    var newValueArray = newValue.split(',').sort();
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('rubrics') != null) {
      var rubrics = urlParams.get('rubrics').split(',').sort();
      refresh = !compareArrays(rubrics, newValueArray);
    } else if (urlParams.get('rubrics') == null && newValue.length > 0) {
      refresh = true;
    }

    if (refresh) {
      refreshFachthemenPage(newValue);
    }

  });

  $(document).on('click', '.theme_list .closeButton', function (e) {
    $('.theme_list .rubrics_select').click();
  });

  $(document).on('click', '.theme_list ul.rubrics_selected li', function (e) {
    var id = $(this).data('id');
    var label = $(this).data('label');
    $('.fachthemen.floatMenu input#rubric' + id).prop('checked', false);

    var selected = $('.theme_list .rubrics_select.select-wrapper.dropdown .initialized option:selected:enabled');
    var newValue = '';
    $(selected).each(function () {
      if ($(this).data('label') != label) {
        newValue += $(this).data('label') + ',';
      }
    });
    newValue = newValue.substring(0, newValue.length - 1);
    refreshFachthemenPage(newValue);
  });

  $('#slider-article-slide').on('slid.bs.carousel', function (event) {
    var bannerActive = $('.banner-item.active');

    if (bannerActive.length > 0) {
      $('.carousel-indicators-fachthemen').css('display', 'none');
    } else {
      $('.carousel-indicators-fachthemen').css('display', 'block');
    }

    var activeId = $("#slider-article-slide .carousel-item.active").data("id");
    var prev = $("#slider-article-slide .slider-articles-teaser .active");
    var active = $("#slider-article-slide .slider-articles-teaser [data-id=" + activeId + "]");

    prev.removeClass("active");
    active.addClass("active");

  });

  var cookieThemen = $.cookie(cookieRubrics);
  if (cookieThemen) {
    $.each(cookieThemen.split(','), function (index, rubric) {
      $('.fachthemen.floatMenu input#rubric' + rubric).attr('checked', true);
    });
  }

  $(document).on('click', '.navigation .mainNav.navbar .fachthemen', function () {
    if ($(this).hasClass('btn-amber-text')) {
      $(this).removeClass('btn-amber-text');
      $(this).addClass('btn-primary');
    } else {
      $(this).addClass('btn-amber-text');
      $(this).removeClass('btn-primary');
    }

    $('.fachthemen.floatMenu').toggle();
  });

  checkButtonGo();

  $(document).on('click', '.fachthemen.floatMenu input', function (e) {
    checkButtonGo();
  });

  $(document).on('click', '.fachthemen.floatMenu .buttons .btn.go', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var url = $('.navigation .mainNav.navbar .fachthemen_url');
    var href = url.attr('href');
    var paramRubrics = '';
    var paramRubricsIds = '';

    $('.fachthemen.floatMenu input:checked').each(function (index) {
      paramRubrics += $(this).data('label') + ',';
      paramRubricsIds += $(this).val() + ',';
    });

    paramRubrics = paramRubrics.substring(0, paramRubrics.length - 1);
    paramRubricsIds = paramRubricsIds.substring(0, paramRubricsIds.length - 1);
    href += '?rubrics=' + paramRubrics;
    saveRubricsCookie(paramRubricsIds);

    location.href = encodeURI(href);
  });



  $(document).on('click', '.fachthemen.floatMenu .buttons .btn.goAll', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var url = $('.navigation .mainNav.navbar .fachthemen_url');
    var href = url.attr('href');

    location.href = encodeURI(href);
  });

  $(document).on('click', '.switch-land .flag-container', function () {
    setCurrentLand('switch-land-form', this);
  });

  $(document).on('click', '.land-selector .dropdown-item.land', function () {
    setCurrentLand('switch-land-selector-form', this);

  });

  initDatePickers();

  $(document).on('click', '.ui-datepicker-year', function () {
  });

  $(document).on('click', '.articleMediaButtons .socialTrigger', function () {
    $('.articleMediaButtons .socialIcons').toggle();
  });

  $(document).on('change', '.registration select.beruf', function () {
    checkBerufSelect();
  });



  $(document).on('click', '.registration button.submitRegisterFormTrigger', function () {
    $('.registration form button.submitRegisterForm').click();
    $("button[type=button].submitRegisterFormTrigger").prop("disabled", true);
  });

  $(document).on('click', '.navbar-fixed-top .container .navbar .fa.search', function () {
    $('.navbar-fixed-top .container .navbar .navigation').show();
    $('.navbar-fixed-top .container .navbar .search').hide();
  });

  $(document).on('click', '.navbar-fixed-top .container .navbar .fa.navigation', function () {
    $('.navbar-fixed-top .container .navbar .navigation').hide();
    $('.navbar-fixed-top .container .navbar .search').show();
  });

  if ($('.registration select.beruf').length > 0) {
    checkBerufSelect();
    apothekeSelect();
    checkFachgebeitSelect();
  }

  $(document).on('click', '.registration .radio.fachbereiche input[type=radio]', function (event) {

    var actual = $('.registration form.form-register input.fachbereiche.values').val();
    if (actual.indexOf($(this).val()) >= 0) {
      $(this).prop('checked', false);
    }

    var values = "";
    $('.registration .radio.fachbereiche input[type=radio]:checked').each(function () {
      values += $(this).val() + ",";
    });
    $('.registration form.form-register input.fachbereiche.values').val(values);
  });

  if ($(".html-string").length > 0) {
    var log = $(".html-string");
    var str = $("div.html-string").text();
    var html = $.parseHTML(str);
    log.append(html);
    $(".html-string").contents().filter(function () {
      return (this.nodeType == 3);
    }).remove();

  }

  changeFachthemenSelects();

  //registration
  $(document).on('change', '.registration form select.gender', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.registration .gender input').click();
  });

  $(document).on('show.bs.dropdown', '.registration .gender.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.gender').next().addClass('focused');
  });

  $(document).on('hidden.bs.dropdown', '.registration .gender.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.gender').next().removeClass('focused');
  });

  $(document).on('click', '.registration .gender.select-wrapper.dropdown span.caret', function (e) {
    $('.registration .gender input').click();
  });

  $(document).on('change', '.registration form select.land', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.registration .land input').click();
  });

  $(document).on('show.bs.dropdown', '.registration .land.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.land').next().addClass('focused');
  });

  $(document).on('hidden.bs.dropdown', '.registration .land.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.land').next().removeClass('focused');
    $('.registration form input.registration_btn').click();
  });

  $(document).on('click', '.registration .land.select-wrapper.dropdown span.caret', function (e) {
    $('.registration .land input').click();
  });

  $(document).on('change', '.registration form select.beruf', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.registration .beruf input').click();
    apothekeSelect();
    checkFachgebeitSelect();

  });

  $(document).on('show.bs.dropdown', '.registration .beruf.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.beruf').next().addClass('focused');
  });

  $(document).on('hidden.bs.dropdown', '.registration .beruf.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.beruf').next().removeClass('focused');
  });

  $(document).on('click', '.registration .beruf.select-wrapper.dropdown span.caret', function (e) {
    $('.registration .beruf input').click();
  });

  $(document).on('show.bs.dropdown', '.registration .fachgebiet.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.fachgebiet').next().addClass('focused');
  });

  $(document).on('hidden.bs.dropdown', '.registration .fachgebiet.select-wrapper.dropdown', function (e) {
    $('.registration .select-wrapper.fachgebiet').next().removeClass('focused');

    var selected = $('.registration .fachgebiet option:selected:enabled');
    var newValue = '';
    $(selected).each(function () {
      newValue += $(this).val() + ',';
    });
    newValue = newValue.substring(0, newValue.length - 1);
    $('.registration form input.registration_fachgebiet_hidden').val(newValue);
  });

  $(document).on('blur', '.registration .fachgebietTextInput input', function () {
    newValue = $('.fachgebietTextInput input').val();
    $('.registration form input.registration_fachgebiet_hidden').val(newValue);
  });

  $(document).on('click', '.registration .fachgebiet.select-wrapper.dropdown span.caret', function (e) {
    $('.registration .select-wrapper.fachgebiet input.dropdown-toggle').click();
  });

  $(document).on('click', '.registration .fachgebiet .custom_container', function () {
    $('.registration .select-wrapper.fachgebiet input.dropdown-toggle').click();
  });

  listenFachgebietOptions();
  showFachgebietOptions();
  addFachgebietTooltip();
  // end registration

  //Merken
  $(document).on('click', '.article-content .articleMediaButtons .merken i.fa', function () {
    $('.article-content .articleMediaButtons .merken form input.bookmarkTrigger').click();
  });

  checkNewsletter();

  $(document).on('change', 'form.newsletter-feed select', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('form.newsletter-feed input.udpateFeed').click();
  });

  $('a[data-scroll]').click(function (e) {
    e.preventDefault();
    var offset = 150;
    var target = ('[data-name="' + $(this).data('scroll') + '"]');
    var $target = $(target);

    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - offset
    }, 800, 'swing');
  });

  var sideBar = $('.leftSidebar');
  var sticky = sideBar.offset();
  var updateCounter = 0;

  $(window).scroll(function () {
    var ldbBanner = $('#gam_ldb01');

    if (ldbBanner.length >= 1) {
      if (ldbBanner[0] != null) {
        if (ldbBanner[0].offsetHeight > 0 && updateCounter == 0) {
          updateCounter++;

          if (sticky != null) {
            sticky.top = sticky.top + 90;
          }
        }
      }
    }

    if (window.matchMedia("screen").matches && sideBar.length > 0 && window.pageYOffset >= sticky.top) {

      var offset = window.pageYOffset - sticky.top;
      var articleMainBottom = $(".csArticle-page_main").offset().top + $(".csArticle-page_main").innerHeight();

      // Middle
      if (window.pageYOffset <= articleMainBottom - sideBar.innerHeight()) {
        // sideBar.offset({ top: window.pageYOffset + 150, left: sticky.left });
        var top = screen.width < 1024 ? sticky.top + offset - 200 : offset;

        top = top < 0 ? 0 : top;
        sideBar.stop().animate({
          'top': top
        }, 500);
      } else {
        // Bottom
        var top = screen.width < 1024 ? articleMainBottom - sideBar.innerHeight() - 200 : articleMainBottom - sticky.top - sideBar.innerHeight();

        top = top < 0 ? 0 : top;

        sideBar.stop().animate({
          'top': top
        }, 500);
      }
    } else {
      // Top
      sideBar.offset(sticky);
    }
  });

  $('.buttonResponsiveAside').click(function (e) {
    e.preventDefault();

    if ($('.buttonResponsiveAside').hasClass('opened')) {
      $('.leftSidebar').stop().animate({
        'left': '100%'
      }, 800, 'swing');

      $('.buttonResponsiveAside').removeClass('opened');
    } else {
      $('.leftSidebar').stop().animate({
        'left': '10%'
      }, 800, 'swing');

      $('.buttonResponsiveAside').addClass('opened');
    }

  });

  var popupshown = [];

    if (localStorage.popupShown) {
        popupshown = JSON.parse(localStorage.popupShown);

        var monthAgo = new Date();
        monthAgo.setDate(monthAgo.getDate() - 30);

        // var monthAgo = new Date(Date.now() - 30000);

        popupshown = popupshown.filter(function (val) {
            return new Date(val) > monthAgo;
        });
    }


    if (popupshown.length < 3 && $('#newsletter-pop-up').length > 0 && !sessionStorage.popupShown) {

        $('#newsletter-pop-up').modal({
            backdrop: true,
            show: true
        });

        popupshown.push(new Date());

        sessionStorage.popupShown = true;
        localStorage.popupShown = JSON.stringify(popupshown);
    }

    console.log();

    if ($('#testModal').length > 0 && window.location.search.includes('aplus=1')) {

      $('#testModal').modal({
          backdrop: true,
          show: true
      });
    }

    // window.onload = function(){
    //   document.getElementById('close').onclick = function(){
    //       $('#testModal').modal('hide');
    //       return false;
    //   };
    // };

    $(document).on('change', '#newsletter-pop-up #cbox', function (e) {
      e.preventDefault();
      e.stopPropagation();

      $('#newsletter-pop-up .submit').prop("disabled", !$(this).is(":checked"));
  });

  $(document).on('click', '.video-articles .article-list article .loadVideo', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var articleIndex = $(this).data("article-index");
    $(".selectedVideo").val(parseInt(articleIndex));
    $('.video-articles .js-result-replace').data('target-url', $('.video-articles .js-result-replace').data('target-url').replace(/\&selectedVideo\=\d+/g,''));
    $(".load-video_btn-submit").click();
  });

  $(document).on('click', '.privacy', function (e) {
    if ($('.privacy').prop('checked') ) {
      $('.newsletter-button').prop('disabled', false);
    } else {
      $('.newsletter-button').prop('disabled', true);
    }
  });

  // $(document).on('click', '.accept', function (e) {
  //   if ($('.privacy').prop('checked') && $('.accept').prop('checked') ) {
  //     $('.newsletter-button').prop('disabled', false);
  //   } else {
  //     $('.newsletter-button').prop('disabled', true);
  //   }
  // });

  $(document).on('click', '.footer-privacy', function (e) {
    if ($('.footer-privacy').prop('checked') ) {
      $('.footer-newsletter-button').prop('disabled', false);
    } else {
      $('.footer-newsletter-button').prop('disabled', true);
    }
  });

  // $(document).on('click', '.footer-accept', function (e) {
  //   if ($('.footer-privacy').prop('checked') && $('.footer-accept').prop('checked')) {
  //     $('.footer-newsletter-button').prop('disabled', false);
  //   } else {
  //     $('.footer-newsletter-button').prop('disabled', true);
  //   }
  // });

  $(document).mouseup(function(e)
  {
      var container = $(".fachthemen-submenu");
      var container2 = $(".sublist");

      // if the target of the click isn't the container nor a descendant of the container
      if ((!container.is(e.target) && container.has(e.target).length === 0) && (!container2.is(e.target) && container2.has(e.target).length === 0))
      {

          container.hide();
          container.each(function() {
            $('[data-id=' + $(this)[0].id + ']').removeClass("active");
          });

          openCurrentMenu(false);
      }
  });

  $(document).on('click', '.fach-list .fach-list-wrapper .sublist', function (e) {

   /*var id = $(this).data("id");
   var display = $('#' + id).css("display");

   $('.fach-list .fach-list-wrapper .sublist').each(function() {
      $(this).removeClass("active");
    });

   $('.fachthemen-submenu').each(function() {
     $(this).css("display", "none");
   });

   if (display === 'none') {
    $('#' + id).css("display", "flex");
    $(this).addClass("active");

    // console.log($(this).offset().left);
    // var left = $(this).offset().left;

    /*$(this).parents('.fach-list').animate({
      scrollLeft: left
    }, 100);



   }*/

   var id = $(this).data("id");
   sublistClick(id);

  });

  $(document).on('click', '.newest-articles .load-more_btn_newest', function($event) {
    $event.stopPropagation();
    removeInvalidParamsNewest();
    $('.newest-articles .load-more_btn-submit').click();
  });

  var url = window.location.href.split('?');
  var params = url[1];
  var selectedVideo = '';

  if (!!params) {
    var moreParams = params.split('&');
    for (var i = 0; i < moreParams.length; i++) {
      if (moreParams[i].includes('selectedVideo')) {
        selectedVideo = moreParams[i];
        var selectedVideoSplit = selectedVideo.split('=');
        selectedVideo = selectedVideoSplit[1];
      }
    }

    if (selectedVideo != '') {
      $(".selectedVideo").val(parseInt(selectedVideo));
      $('.video-articles .js-result-replace').data('target-url', $('.video-articles .js-result-replace').data('target-url').replace(/\&selectedVideo\=\d+/g,''));
      $(".load-video_btn-submit").click();
      location.href="#videowidget"
    }
  }

  loadContent();

  relocateGoogleBanners(1900);

  hideEmptySidebarGoogleBanners(1900);

  window.onmessage = function(event) {
    if (typeof event.data == 'string' && event.data.startsWith('gam_')) {
      var banner = $("#" + event.data);
      var bannerArticleListParent = banner.closest('.article-list-wrapper');
      var bannerHeaderArticleParent = banner.closest('.article-header');
      var bannerContentArticleParent = banner.closest('.main-content-widget');

      if (bannerArticleListParent.length > 0) {
        var bannerParent = banner.closest('.article-wrapper.medium-rectangle');

        if (bannerParent.length > 0) {
          // Hide fachthemen list medium-rectangle banners
          bannerParent.addClass('emptyBanner');

          var list = $('.article-wrapper.fx-flex-32');
          moveLdbBanners(list, 0);
        } else {
          bannerParent = banner.closest('.article-wrapper.ldb');

          if (bannerParent.length > 0) {
            // Hide fachthemen ldb banners
            bannerParent.addClass('emptyLdbBanner');
          }
        }
      } else if (bannerContentArticleParent.length > 0 || bannerHeaderArticleParent.length > 0) {
        // Hide detail article ldb banners
        banner.addClass('emptyLdbBanner');
      } else {
        // Hide sidebar medium-rectangle banners
        banner.addClass('emptyBanner');
      }
    }
  };

  $(document).on('click', '.registration form.form-register-newsletters-eyepin button.submitNewsletterEyepinOptionsFormTrigger', function(e){
      if ($('.newsletters_page .csPreloader').length > 0) {
          $('.newsletters_page .csPreloader').toggle();
      }
      var values = "";
      $('.registration form.form-register-newsletters-eyepin input[type="checkbox"]:checked').each(function() {
          values += $(this).val() + ",";
      });
      $('.registration form.form-register-newsletters-eyepin input.registration_newsletterEyepinOptions_hidden').val(values);
      $('.registration form.form-register-newsletters-eyepin input[type="submit"].newsletterEyepinOptions_btn').click();
  });




/************************
   *  IMPORTANT FOR GTM
   ************************/
    checkDatalayer();

});

function relocateGoogleBanners(time) {
  setTimeout(function() {
    var mrGoogleBanners = $('.article-wrapper.fx-flex-32.medium-rectangle');
    if (mrGoogleBanners.length > 0) {
      for (var i = 0; i < mrGoogleBanners.length; i++) {
        if ($(mrGoogleBanners[i]).find('iframe').length === 0) {
          $(mrGoogleBanners[i]).addClass('emptyBanner');
        }
      }
    }

    var emptyGoogleBanners = $('.article-wrapper.fx-flex-32.emptyBanner');
    if (emptyGoogleBanners.length > 0) {
      var list = $('.article-wrapper.fx-flex-32');
      moveLdbBanners(list, 0);
    }

    addingFakeArticles();
  }, time);
}

function moveLdbBanners(list,  emptyCounter) {
  var next = 0;
  var noArticleCounter = 0;
  var articleFromBeginingCounter = 0;
  var articleFromBegining = true;

  for (var i = 0; i < list.length; i++) {
    if ($(list[i]).hasClass('medium-rectangle') || $(list[i]).hasClass('widget')) {
      noArticleCounter++;
      articleFromBegining = false;
    } else if (!($(list[i]).hasClass('medium-rectangle')) && !($(list[i]).hasClass('emptyBanner')) && !($(list[i]).hasClass('ldb')) && articleFromBegining) {
      articleFromBeginingCounter++;
    } else if ($(list[i]).hasClass('ldb')) {
      articleFromBegining = false;
    }

    if ($(list[i]).hasClass('emptyBanner')) {
      if (next > -1) {
        next = searchNextArticle(list, next + 1);
        $(list[i]).after(list[next]);
      }
    } else if ($(list[i]).hasClass('ldb')) {
      for (var j = 1; j <= 6 - noArticleCounter - articleFromBeginingCounter; j++) {
        if (next > -1) {
          next = searchNextArticle(list, next + 1);
          $(list[i]).before(list[next]);
        }
      }

      noArticleCounter = 0;
      articleFromBeginingCounter = 0;
    }
  }

  var newList = $('.article-wrapper.fx-flex-32');
  var realPosition = 3;
  var sorting;

  for (var i = 0; i < newList.length; i++) {
    if (!($(newList[i]).hasClass('emptyBanner'))) {
      realPosition++;
    }

    if ($(newList[i]).hasClass('medium-rectangle') && !($(newList[i]).hasClass('emptyBanner'))) {
      sorting = $(newList[i]).attr('data-sorting');

      if (sorting != realPosition) {
        for (var j = 1; j <= Math.abs(sorting - realPosition); j++) {
          if (sorting > realPosition) {
            newList[i].before(newList[i + j]);
          } else {
            newList[i].after(newList[i + j]);
          }
        }

        newList = $('.article-wrapper.fx-flex-32');
      }
    }
  }

  addingFakeArticles();
}

function addingFakeArticles() {
  var totalElements = $('.article-wrapper.fx-flex-32').length;
  var emptyBannerElements = $('.article-wrapper.fx-flex-32.emptyBanner').length;
  var ldbBannerElements = $('.article-wrapper.fx-flex-32.ldb').length;
  var shownElements = totalElements - emptyBannerElements - ldbBannerElements;

  if (shownElements%3 == 2) {
    if ($('.article-wrapper.fx-flex-32').last().hasClass("ldb")) {
      $("<div class=\"article-wrapper fx-flex-32 fakeArticle\"></div>").insertBefore($('.article-wrapper.fx-flex-32').last());
    } else {
      $("<div class=\"article-wrapper fx-flex-32 fakeArticle\"></div>").insertAfter($('.article-wrapper.fx-flex-32').last());
    }
  }
}

function reloadGoogleBanners() {
}

function hideEmptySidebarGoogleBanners(time) {
  setTimeout(function() {
    var sidebarGoogleBanners = $('.sidebar-widget .configurable-widget.medium-rectangle');
    var lastWidgetBottom = 0;
    if (sidebarGoogleBanners.length > 0) {
      for (var i = 0; i < sidebarGoogleBanners.length; i++) {
        if ($(sidebarGoogleBanners[i]).find('iframe').length === 0) {
          $(sidebarGoogleBanners[i]).addClass('emptyBanner');
        }
      }

      var relatedArticle = $('.related-articles');

      if (relatedArticle.length > 0) {
        var relatedArticleOffset = relatedArticle.offset();
        var sidebar = $('.sidebar');

        // if (relatedArticleOffset != 'undefined' && sidebar.offset().top + sidebar.height() > relatedArticleOffset.top && window.innerWidth > 1024) {
        //   var marginTop = sidebar.offset().top + sidebar.height() - relatedArticleOffset.top;
        //   $('.related-articles').css('margin-top', marginTop);
        // }
      }
    }
  }, time);
}

function searchNextArticle(list, bannerIndex) {
  var result = -1;
  var i = bannerIndex;
  while (i < list.length && result == -1) {
    if (!($(list[i]).hasClass('ldb')) && !($(list[i]).hasClass('medium-rectangle')) && !($(list[i]).hasClass('widget'))) {
      result = i;
    }

    i++;
  }

  return result;
}

function showGoogleBanner(id) {
  var show = true;

  console.log(id);

  return show;
}

function sublistClick(id) {
   var display = $('#' + id).css("display");

   $('.fach-list .fach-list-wrapper .sublist').each(function() {
      $(this).removeClass("active");
    });

   $('.fachthemen-submenu').each(function() {
     $(this).css("display", "none");
   });

   if (display === 'none') {
    $('#' + id).css("display", "flex");
    $(this).addClass("active");
    $("#h3-title").addClass("mt-4");
   }
}

function closePopUp() {
  //3 mins
  setTimeout(function () { $('#newsletter-pop-up').modal('hide'); }, 180000);
}



function checkNewsletter() {
  var content = $('.newsletter-feed-current .replace');
  if (content.length > 0) {
    var url = content.data('url');
    $(content).load(url);
  }
}

function checkButtonGo() {
  if ($('.fachthemen.floatMenu input:checked').length > 0) {
    $('.fachthemen.floatMenu .buttons .btn.go').removeClass('disabled');
  } else {
    $('.fachthemen.floatMenu .buttons .btn.go').addClass('disabled');
  }
}

function listenFachgebietOptions() {
  var fachgebietList = $('.registration .fachgebiet ul');
  fachgebietList.find('li:not(.optgroup)').each(function (i) {
    $(this).click(function (e) {
      var selected = $('.registration .fachgebiet option:enabled:selected');
      if (selected.length > 3) {
        $(this).click();
      }
      showFachgebietOptions();
      addFachgebietTooltip();
    });
  });
}

function addFachgebietTooltip() {
  var selected = $('.registration .fachgebiet option:enabled:selected');
  var fachgebietOptions = $('.fachgebiet select option');
  var fachgebietLi = $('.fachgebiet li');
  fachgebietLi.each(function (i) {
    if (!$(this).hasClass('disable')) {
      var tooltip = fachgebietOptions.eq(i).text();
      if (selected.length >= 3) {
        tooltip = $('.registration .fachgebiet_tooltip_text').data('text');
      }
      $(this).attr('title', tooltip);
      $(this).attr('data-original-title', tooltip);
      $(this).tooltip({ 'title': tooltip });
    }
  });
}

function showFachgebietOptions() {
  var parent = $('.registration .fachgebietSelected .fachgebiet_selected');
  var selected = $('.registration .fachgebiet option:enabled:selected');
  if (selected.length > 0) {
    parent.show();
    parent.find('ul').remove();
    parent.append('<ul class="fachgebiet_selected_list"></ul>');
    var list = parent.find('ul');
    selected.each(function (index) {
      var option = $(this).text();
      list.append('<li>' + option + '</li>');
    });

    var fachgebietInput = $('.registration .fachgebiet input.select-dropdown');
    $('.registration .fachgebiet').find('div.custom_container').remove();

    if (selected.length > 1) {
      var content = $(selected[0]).text();
      if (content.length > 20) {
        content = content.substring(0, 19) + '...';
      }
      var more = selected.length - 1;
      fachgebietInput.val('');
      fachgebietInput.hide();
      fachgebietInput.after('<div class="custom_container"><span class="custom_label">' + content + '</span><span class="custom_label more">+' + more + '</span></div>');
    } else {
      fachgebietInput.show();
    }
  } else {
    parent.hide();
  }
}

//Tracking clicks in GoogleAnalytics
function trackOutboundLink(e, url) {
  e.preventDefault();
  window.open(url, '_blank');

  gtag('event', 'click', {
    'event_category': 'outbound',
    'event_label': url,
    'transport_type': 'beacon'
  });
}

function isArztBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'arzt' || beruf.toLowerCase() === 'médecin' || beruf.toLowerCase() === 'arzt in ausbildung' );
}

function isIndustrieBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'industrie');
}

function isInternBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'intern');
}

function isMedizinischerBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'medizinischer beruf');
}

function isApothekeBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'apotheker');
}

function isStudentinBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'student:in');
}

function isMedizinischesBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'medizinisches berufsumfeld');
}

function isSonstigesBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && (beruf.toLowerCase() === 'sonstiges');
}

function isAndereBeruf() {
  var beruf = $('.registration .beruf input').val();
  if (beruf == null) {
    beruf = $('.registration select.beruf').val();
  }
  return beruf != null && beruf.toLowerCase().startsWith('andere');
}

function isMainLand() {
  var land = $('.registration form select.land').val();
  return land != null && (land == '1' || land == '2' || land == '7');
}

function isSelectedLand(selectedLand) {
  var land = $('.registration form select.land').val();
  return land != null && land == selectedLand;
}

function checkFachgebeitSelect() {

  if (isArztBeruf()) {
    $('.registration .fachgebietWrapper').css('display', 'block');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'block');
    $('.registration .oakWrapper').css('display', 'block');
  } else if (isIndustrieBeruf()) {
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'block');
    $('.registration .abteilungBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else if (isInternBeruf()) {
    $('.registration .internBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
    var email = $('.registration .forFuturo input').val();
    if (email.toLowerCase().endsWith("futuroholding.com") ||
    email.toLowerCase().endsWith("universimed.com") ||
    email.toLowerCase().endsWith("medmedia.at") ||
    email.toLowerCase().endsWith("medahed.at")) {
      $('.registration li span:contains("Futuro")').css('display', 'block');
    } else {
      $('.registration li span:contains("Futuro")').css('display', 'none');
    }
  } else if (isMedizinischerBeruf()) {
    $('.registration .medizinischerBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else if (isApothekeBeruf()) {
    $('.registration .apothekeBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else if (isStudentinBeruf()) {
    $('.registration .studentinBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else if (isMedizinischesBeruf()) {
    $('.registration .medizinischesBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else if (isSonstigesBeruf()) {
    $('.registration .sonstigesBerufWrapper').css('display', 'block');
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
  } else {
    $('.registration .fachgebietWrapper').css('display', 'none');
    $('.registration .branchenspezifikationenBerufWrapper').css('display', 'none');
    $('.registration .abteilungBerufWrapper').css('display', 'none');
    $('.registration .internBerufWrapper').css('display', 'none');
    $('.registration .medizinischerBerufWrapper').css('display', 'none');
    $('.registration .apothekeBerufWrapper').css('display', 'none');
    $('.registration .studentinBerufWrapper').css('display', 'none');
    $('.registration .medizinischesBerufWrapper').css('display', 'none');
    $('.registration .sonstigesBerufWrapper').css('display', 'none');
    $('.registration .fachgebietSelected').css('display', 'none');
    $('.registration .oakWrapper').css('display', 'none');
    $('.registration form input.registration_fachgebiet_hidden').val('');
  }

  changeCustomChanges();
}

function changeCustomChanges() {

  if (!isArztBeruf() && isMainLand()) {
    $('.registration .verwendungWrapper').css('display', 'block');
  } else {
    $('.registration .verwendungWrapper').css('display', 'none');
  }

  if (isArztBeruf() && !isMainLand()) {
    $('.registration .other_lands_arzt').css('display', 'block');
  } else {
    $('.registration .other_lands_arzt').css('display', 'none');
  }

  if (isAndereBeruf() && !isMainLand()) {
    $('.registration .other_lands_andere').css('display', 'block');
  } else {
    $('.registration .other_lands_andere').css('display', 'none');
  }

  if (isArztBeruf() && isSelectedLand('7')) {
    $('.registration .abfrageWrapper').css('display', 'block');
  } else {
    $('.registration .abfrageWrapper').css('display', 'none');
  }
}

function checkBerufSelect() {
  if (isArztBeruf()) {
    $('.registration form div.arztOption').show();
  } else {
    $('.registration form div.arztOption').hide();
    $('.registration form .arztOption').val('');
  }
  changeCustomChanges();
}

function apothekeSelect() {

  if (isApothekeBeruf()) {
    $('.registration .apothekeWrapper').css('display', 'block');
    $('.registration .eintragungsWrapper').css('display', 'none');
  } else {
    $('.registration .eintragungsWrapper').css('display', 'block');
    $('.registration .apothekeWrapper').css('display', 'none');
  }
}

function initDatePickers() {
  $('.form-register #birthday').datepicker({ changeYear: true, yearRange: '1930:c', dateFormat: "dd-mm-yy" });
  $('.form-account #birthday').datepicker({ changeYear: true, yearRange: '1930:c', dateFormat: "dd-mm-yy" });
}

function compareArrays(arr1, arr2) {
  return $(arr1).not(arr2).length === 0 && $(arr2).not(arr1).length === 0;
}

function refreshFachthemenPage(newValue) {
  var href = window.location.href.replace(window.location.search, '');
  href += '?rubrics=' + newValue;
  location.href = encodeURI(href);
}

function docCheck() {
  var userdiv = document.getElementById('userdata');
  if(userdiv != null){



    var land = userdiv.getAttribute('data-land');
    var beruf = userdiv.getAttribute('data-beruf');
    var verified = userdiv.getAttribute('data-verified');
    var gln = userdiv.getAttribute('data-gln');



    console.log("Land: "+land);
    console.log("beruf: "+beruf);
    console.log("verified: "+verified);
    console.log("gln: "+gln);

    // Swiss User
    if(land =='2'){
      // Doctor
        if(beruf.toLowerCase() === 'arzt' || beruf.toLowerCase() === 'médecin'){
            if(gln != null && verified == '1'){
              return '&verified=true';

            }else{
              return '&verified=false';
            }
        }
        // no Doctor
        else if(beruf === 'apotheker' || beruf === 'pflegepersonal' || beruf === 'zahnarzt' || beruf === 'physiotherapie' || beruf === 'medizinisch_technische_dienste' || beruf === 'andere_medizinische_Tatigkeit'){
          return '&verified=true';

        }else{
          return '&verified=false';
        }
    }else{
      return '&verified=false';
    }
  }else{
    return '&verified=false';
  }

}
function addBannerAttribute() {
  var bannerelement = document.getElementsByClassName('adContainer');
  var length = bannerelement.length;
  for (var i = 0; i < length; i++) {
    var bannerSrc = bannerelement[i].getAttribute('src');
      console.log("src: "+bannerSrc);
  };
}


setTimeout(
  function()
  {


    // if ($(".mydivclass")[0]){
    //     // Do something if class exists
    // } else {
    //     // Do something if class does not exist
    // }

    // if ($('#banners').children('div').length == 0) {
    //   var pid=$(this).attr("data-asm-params");
    //   console.log("kein banner: "+pid );
    //      //$(this).attr(height)
    // }

    $( "ins" ).each(function( index ) {
      if($( this ).children('div').length == 0){
        console.log( index + ": " + $( this ).attr('data-asm-params') );
        $( this ).parent( ".configurable-widget" ).css( "margin-bottom", "0" );
      }
      //console.log( index + ": " + $( this ).attr('data-asm-params') );
    });

  }, 1500);


// Lazy load

function loadContent() {
  $('.lazyload').each(function() {
      $(this).click();
  })
}


/********************************
 *
 *  DATA LAYER GTM
 *
 ********************************/

function checkDatalayer(){
  let F1, F2, F3;
        if ($("#userdata").length > 0) {
            lgoinInfo = 'true';
            beruf = $("#userdata").attr('data-beruf');

            if(beruf == 'arzt' || beruf == 'arzt_in_ausbildung'){
                fachgebiete = $("#userdata").attr('data-fachgebiet');
                var fArray = fachgebiete.split(',');
                if(fArray[0] != null){
                    F1 = fArray[0].toString();
                }
                if(fArray[1] != null){
                    F2 = fArray[1];
                }
                if(fArray[2] != null){
                    F3 = fArray[2];
                }

            }else{
                F1 = beruf
            }


        }else{
            lgoinInfo = 'false';
            F1 = 'sonstiges';
        }
        /** Check if Article is from Newsletter **/
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        const product = urlParams.get('src')
        if(product == 'nl'){
          lgoinInfo = 'newsletter-login';
        } else{
          lgoinInfo = lgoinInfo;
        }

        window.dataLayer = window.dataLayer || [];
            if(F2 != null && F3 == null){
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'F2': F2,
                    'articleDate': articleDate
                }];
            } else if(F3 != null){
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'F2': F2,
                    'F3':F3,
                    'articleDate': articleDate
                }];
            }else{
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'articleDate': articleDate
                }];
            }

    }













